export default {
  "p-index-head-title":
    "Solid Swap | Intercambio de criptomonedas seguro y eficiente",
  "p-index-head-description":
    "Experimenta el comercio de criptomonedas rápido, seguro y eficiente con Solid Swap. Únete ahora para comenzar a intercambiar activos digitales con facilidad",
  "p-index-head-keywords":
    "intercambio de criptomonedas, comercio seguro, activos digitales, comercio de cripto, Solid Swap, solidswap",
  "p-index-prices-finder": "Buscador de precios:",
  "p-index-welcome-title":
    "Bienvenido a tu experiencia de trading P2P sin interrupciones",
  "p-index-welcome-message-2":
    "Comercia a tu manera, en cualquier momento y lugar",
  "p-index-welcome-no-fees":
    "Sin comisiones para el comprador, comisión ultra baja del 0.2% para el vendedor",
  "p-index-welcome-stable": "Estable, legalizado y libre de ALM",
  "p-index-welcome-no-id": "No se requiere verificación de ID",
  "p-index-get-started": "COMENZAR",
  "p-index-learn-more": "APRENDE MÁS",
  "p-index-world-opening-title": "Estamos abriendo el mundo para ti",
  "p-index-world-opening-message-1":
    "La criptomoneda te hará libre, es libertad de las cadenas de los bancos, el dictado del gobierno",
  "p-index-world-opening-message-2":
    "Esto es libertad de movimiento alrededor del mundo y la capacidad de guardar tu dinero en cualquier país",
  "p-index-mobile-app-title": "Descarga la aplicación móvil gratis",
  "p-index-mobile-app-message":
    "La aplicación móvil está disponible para descargar en la App Store y Google Play",
  "p-index-coming-soon": "Próximamente este verano",
  "p-index-subscribe-title": "Suscríbete a Solid Swap",
};
