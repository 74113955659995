export default {
  "p-faq-head-title":
    "FAQ | Questions courantes sur l'échange de cryptomonnaies Solid Swap",
  "p-faq-head-description":
    "Trouvez des réponses aux questions les plus fréquemment posées sur Solid Swap. En savoir plus sur notre plateforme, nos mesures de sécurité et comment trader des cryptomonnaies",
  "p-faq-head-keywords":
    "FAQ, questions sur l'échange de cryptomonnaies, FAQ Solid Swap, aide au trading crypto",
  "p-faq-title": "FAQ sur Solid Swap",
  "p-faq-q1-title": "Qu'est-ce que Solid Swap ?",
  "p-faq-q1-answer1":
    "Solid Swap est une plateforme pour l'échange rapide de cryptomonnaies et de monnaies fiduciaires, offrant actuellement des services peer-to-peer (P2P).",
  "p-faq-q1-answer2":
    "Nous prévoyons d'introduire la possibilité d'échange instantané de cryptomonnaies dans un avenir proche et de continuer à étendre la gamme de services offerts par notre plateforme.",
  "p-faq-q2-title": "Pourquoi devrais-je faire confiance à Solid Swap ?",
  "p-faq-q2-answer1":
    "Vous pouvez faire confiance à Solid Swap car nous sommes officiellement enregistrés en Arménie, ce qui nous permet d'offrir des conditions pratiques à nos utilisateurs sans avoir besoin de vérification d'identité et de suivi des transactions ALM.",
  "p-faq-q2-answer2":
    "Cela nous libère du rôle d'une banque traditionnelle, qui pourrait geler vos fonds.",
  "p-faq-q2-answer3":
    "Vous pouvez toujours consulter les avis de nos clients sur cette page.",
  "p-faq-q3-title": "Quelle est la commission de trading sur Solid Swap ?",
  "p-faq-q3-answer1":
    "Nous nous efforçons de garantir la transparence dans tous les aspects de nos opérations, y compris les frais.",
  "p-faq-q3-answer2":
    "La commission de trading est de seulement 0,2% du montant de la transaction, ce que le vendeur paie.",
  "p-faq-q3-answer3":
    "Cela vous permet de planifier facilement vos transactions, sachant qu'il n'y aura pas de frais imprévus.",
  "p-faq-q4-title":
    "Quelles devises l'échange prend-il en charge pour le trading ?",
  "p-faq-q4-answer":
    "Notre échange prend en charge des cryptomonnaies telles que BTC, ETH, USDT et XMR, et travaille à ajouter d'autres devises à l'avenir.",
  "p-faq-q5-title":
    "Quels sont les montants minimums pour les dépôts et les retraits sur Solid Swap, et y a-t-il des restrictions sur la taille des commandes ?",
  "p-faq-q5-answer1":
    "Le montant minimum pour les dépôts et les retraits sur la plateforme Solid Swap est de 50 dollars.",
  "p-faq-q5-answer2":
    "Si un montant inférieur à ce montant est reçu, il ne sera pas crédité sur le compte de l'utilisateur.",
  "p-faq-q5-answer3":
    "En ce qui concerne les commandes, la plateforme ne fixe pas de limites sur leur taille - elles sont déterminées par l'acheteur ou le vendeur lui-même.",
  "p-faq-q6-title": "Comment est déterminé le coût de retrait des fonds ?",
  "p-faq-q6-answer1":
    "Le coût de retrait d'argent d'un compte change dynamiquement et dépend du coût actuel des transactions dans le réseau.",
  "p-faq-q6-answer2":
    "Nous travaillons toujours pour vous offrir les conditions les plus favorables et minimiser vos dépenses.",
  "p-faq-q7-title":
    "Quelles mesures de sécurité sont prises après le changement de mot de passe ou les paramètres d'authentification à deux facteurs ?",
  "p-faq-q7-answer1": "Votre sécurité est notre priorité.",
  "p-faq-q7-answer2":
    "Par conséquent, après avoir changé votre mot de passe ou les paramètres d'authentification à deux facteurs, nous appliquons une période de blocage de 24 heures pour les retraits.",
  "p-faq-q7-answer3": "Cela empêche l'accès non autorisé à vos fonds.",
  "p-faq-q8-title":
    "Quand puis-je ouvrir un litige en cas de difficultés avec le vendeur ?",
  "p-faq-q8-answer1":
    "Si vous ne parvenez pas à trouver un accord avec le vendeur, vous pouvez ouvrir un litige 30 minutes après que le problème survienne.",
  "p-faq-q8-answer2":
    "Un bouton pour cela apparaîtra automatiquement dans votre compte personnel, vous permettant de résoudre rapidement tout problème.",
  "p-faq-q9-title": "Combien de temps faudra-t-il pour examiner un litige ?",
  "p-faq-q9-answer1":
    "Nous nous efforçons d'examiner chaque litige aussi rapidement que possible.",
  "p-faq-q9-answer2":
    "Le processus peut prendre jusqu'à 24 heures, dès que toutes les informations demandées sont fournies.",
  "p-faq-q9-answer3":
    "Notre objectif est de vous fournir une résolution juste et rapide.",
};
