import en from "@/lang/en";
import de from "@/lang/de";
import fr from "@/lang/fr";
import ru from "@/lang/ru";
import es from "@/lang/es";
import cn from "@/lang/cn";
import am from "@/lang/am";

export default defineI18nConfig(() => {
  const lang = useCookie("lang");
  let locale: string;
  if (lang.value && lang.value !== "") {
    locale = lang.value;
  } else {
    locale = "en";
  }
  return {
    legacy: false,
    locale,
    fallbackLocale: "en",
    messages: {
      en,
      de,
      fr,
      ru,
      es,
      cn,
      am,
    },
  };
});
