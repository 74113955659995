export default {
  "p-about-head-title":
    "Մասին Solid Swap | Ծանոթացեք մեր կրիպտոարժույթի փոխանակման թիմի հետ",
  "p-about-head-description":
    "Իմացեք ավելին Solid Swap-ի մասին, մեր ապահով կրիպտոարժույթի փոխանակման հարթակի թիմի և մեր առաքելության մասին, որը նպատակաուղղված է ապահովելու արդյունավետ թվային ակտիվների փոխանակում",
  "p-about-head-keywords":
    "կրիպտոարժույթի փոխանակում, անվտանգ առևտուր, թվային ակտիվներ, կրիպտո առևտուր, Solid Swap, solidswap",
  "p-about-title":
    "Solid Swap: ոչ միայն կրիպտո փոխանակում, այլ ձեր ճանապարհը ֆինանսական ազատության:",
  "p-about-why-choose-us-title": "Ինչու՞ ընտրել մեզ:",
  "p-about-fast-secure-title": "Արագ և ապահով գործարքներ",
  "p-about-fast-secure-description":
    "Մենք գնահատում ենք ձեր ժամանակն ու գաղտնիությունը, առաջարկելով ակնթարթային գործարքներ առավելագույն անվտանգությամբ:",
  "p-about-support-title": "24/7 Աջակցություն",
  "p-about-support-description":
    "Մեր առաքելությունն է անընդհատ աշխատել, 24/7, որպեսզի ամեն օր հաստատենք մեր անկրկնելի համբավը և մեր հաճախորդների վստահությունը:",
  "p-about-conditions-title": "Արտոնյալ պայմաններ",
  "p-about-conditions-description":
    "Մեր նպատակն է դարձնել կրիպտոարժույթների գործարքները արագ, ապահով և շահավետ յուրաքանչյուր օգտագործողի համար:",
  "p-about-what-makes-us-unique-title": "Ինչը մեզ յուրահատուկ է դարձնում?",
  "p-about-freedom-anonymity-title": "Ազատություն և անանունություն",
  "p-about-freedom-anonymity-description":
    "Ի տարբերություն շատ հարթակների, որոնք վերածվում են բանկային կառույցների և չեն կարող երաշխավորել բացարձակ անվտանգություն, Solid Swap-ը պահպանում է ազատության և անանունության սկզբունքները: Մենք աջակցում ենք կրիպտոարժույթների սկզբնական նպատակները և տրամադրում ապահով տարածք ձեր գործարքների համար:",
  "p-about-registration-title": "Գրանցում Հայաստանում",
  "p-about-registration-description":
    "Մեր հարթակը գրանցված է երկրում, որը ունի ազատական կրիպտոարժույթի օրենսդրություն, թույլ տալով մեզ գործել օրենքի շրջանակներում, միևնույն ժամանակ պահպանելով մեր անկախությունը և սկզբունքները:",
  "p-about-ease-title": "Օգտագործման հեշտություն",
  "p-about-ease-description":
    "Մեր հարթակը օպտիմիզացված է բոլոր սարքերում օգտագործման համար, ապահովելով հարմարավետ և արդյունավետ կրիպտոարժույթի փոխանակում:",
  "p-about-community-title": "Ուժեղ համայնք",
  "p-about-community-description":
    "Solid Swap-ը ոչ միայն հարթակ է, այլ համայնք, որտեղ յուրաքանչյուր անդամ նպաստում է տնտեսական ազատության և համատեղ բարեկեցության: Միասին մենք ստեղծում ենք էկոհամակարգ, որը բացահայտում է տնտեսական հնարավորություններ յուրաքանչյուր մասնակցի համար:",
  "p-about-subscribe-title": "Բաժանորդագրվել Solid Swap-ին",
  "p-about-contact-title": "Կապվել մեզ հետ",
  "p-about-address": "Հասցե:",
  "p-about-index": "Ինդեքս:",
  "p-about-tax-id": "Հարկային ID:",
  "p-about-email": "Էլ. փոստ:",
  "p-about-support-email": "Աջակցման էլ. փոստ:",
  "p-about-duns": "Տվյալների համընդհանուր համարի համակարգ:",
  "p-about-duns-check": "Ստուգել D-U-N-S",
};
