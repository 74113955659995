export default {
  "p-faq-head-title": "常见问题 | Solid Swap 加密货币交易所常见问题",
  "p-faq-head-description":
    "找到有关 Solid Swap 的最常见问题的答案。了解我们的平台、安全措施以及如何进行加密货币交易",
  "p-faq-head-keywords":
    "常见问题，加密货币交易问题，Solid Swap 常见问题，加密交易帮助",
  "p-faq-title": "关于 Solid Swap 的常见问题",
  "p-faq-q1-title": "什么是 Solid Swap？",
  "p-faq-q1-answer1":
    "Solid Swap 是一个快速交换加密货币和法币的平台，目前提供点对点（P2P）服务。",
  "p-faq-q1-answer2":
    "我们计划在不久的将来引入即时加密货币交换的能力，并继续扩展平台提供的服务范围。",
  "p-faq-q2-title": "为什么我应该信任 Solid Swap？",
  "p-faq-q2-answer1":
    "您可以信任 Solid Swap，因为我们在亚美尼亚注册，这使我们能够在不需要身份验证和反洗钱交易监控的情况下为用户提供便利条件。",
  "p-faq-q2-answer2": "这使我们免于充当传统银行，可以冻结您的资金。",
  "p-faq-q2-answer3": "您可以随时在此页面上查看客户的反馈。",
  "p-faq-q3-title": "Solid Swap 的交易佣金是多少？",
  "p-faq-q3-answer1": "我们在操作的各个方面都追求透明，包括费用。",
  "p-faq-q3-answer2": "交易佣金仅为交易金额的 0.2%，由卖方支付。",
  "p-faq-q3-answer3": "这使您能够轻松计划交易，知道不会有意外费用。",
  "p-faq-q4-title": "交易所支持哪些货币进行交易？",
  "p-faq-q4-answer":
    "我们的交易所支持 BTC、ETH、USDT 和 XMR 等加密货币，并正在努力增加更多货币。",
  "p-faq-q5-title":
    "在 Solid Swap 上存款和取款的最低金额是多少，订单大小是否有任何限制？",
  "p-faq-q5-answer1": "Solid Swap 平台上的最低存款和取款金额为 50 美元。",
  "p-faq-q5-answer2": "如果收到的金额低于此数额，将不会计入用户账户。",
  "p-faq-q5-answer3":
    "至于订单，平台没有设定其大小的限制——由买卖双方自行决定。",
  "p-faq-q6-title": "取款费用是如何确定的？",
  "p-faq-q6-answer1":
    "从账户中取款的费用是动态变化的，取决于当前网络交易的费用。",
  "p-faq-q6-answer2": "我们始终致力于为您提供最优惠的条件并尽量减少您的支出。",
  "p-faq-q7-title": "更改密码或双重身份验证设置后采取了哪些安全措施？",
  "p-faq-q7-answer1": "您的安全是我们的首要任务。",
  "p-faq-q7-answer2":
    "因此，更改密码或双重身份验证设置后，我们会实施 24 小时的提现封锁期。",
  "p-faq-q7-answer3": "这可防止未经授权的资金访问。",
  "p-faq-q8-title": "如果与卖家有困难，什么时候可以开启争议？",
  "p-faq-q8-answer1":
    "如果您无法与卖家达成一致，可以在问题出现后 30 分钟内开启争议。",
  "p-faq-q8-answer2":
    "此按钮将自动出现在您的个人账户中，允许您快速解决任何问题。",
  "p-faq-q9-title": "审查争议需要多长时间？",
  "p-faq-q9-answer1": "我们努力尽快审查每个争议。",
  "p-faq-q9-answer2": "只要提供了所有请求的信息，过程可能需要 24 小时。",
  "p-faq-q9-answer3": "我们的目标是为您提供公正和迅速的解决方案。",
};
