export default {
  "p-index-head-title":
    "Solid Swap | Безопасная и эффективная торговля криптовалютой",
  "p-index-head-description":
    "Ощутите быструю, безопасную и эффективную торговлю криптовалютой с Solid Swap. Присоединяйтесь сейчас, чтобы начать обмен цифровыми активами с легкостью",
  "p-index-head-keywords":
    "криптовалютная биржа, безопасная торговля, цифровые активы, торговля криптовалютой, Solid Swap, solidswap",
  "p-index-prices-finder": "Поиск цен:",
  "p-index-welcome-title":
    "Добро пожаловать в ваш безупречный опыт P2P торговли",
  "p-index-welcome-no-fees":
    "Без комиссии для покупателя, сверхнизкая комиссия 0.2% для продавца",
  "p-index-welcome-stable": "Стабильно, легально и без ALM",
  "p-index-welcome-no-id": "Идентификация не требуется",
  "p-index-welcome-message-2":
    "Торгуйте по вашему усмотрению, в любое время и в любом месте",
  "p-index-get-started": "НАЧАТЬ",
  "p-index-learn-more": "УЗНАТЬ БОЛЬШЕ",
  "p-index-world-opening-title": "Мы открываем для вас мир",
  "p-index-world-opening-message-1":
    "Криптовалюта сделает вас свободным, это свобода от оков банков, диктата правительства",
  "p-index-world-opening-message-2":
    "Это свобода перемещения по миру и возможность сохранить ваши деньги в любой стране",
  "p-index-mobile-app-title": "Скачайте мобильное приложение бесплатно",
  "p-index-mobile-app-message":
    "Мобильное приложение доступно для загрузки в App Store и Google Play",
  "p-index-coming-soon": "Скоро этим летом",
  "p-index-subscribe-title": "Подписаться на Solid Swap",
};
