export default {
  "p-user-index-welcome": "¡Bienvenido a tu panel personal!",

  "p-user-notifications-settings": "Ir a la configuración de notificaciones",
  "p-user-notifications-all": "Todas las notificaciones",
  "p-user-notifications-error": "Error al obtener notificaciones",
  "p-user-notifications-none": "No hay notificaciones",

  "p-user-settings-head-title":
    "Configuración de Usuario | Gestiona tu cuenta de Solid Swap",
  "p-user-settings-head-description":
    "Gestiona la configuración y preferencias de tu cuenta de Solid Swap.",
  "p-user-settings-title": "Configuración de usuario",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Nombre de usuario",
  "p-user-settings-last-login": "Último inicio de sesión",

  "p-user-wallet-head-title":
    "Cartera de Usuario | Gestiona tu cuenta de Solid Swap",
  "p-user-wallet-title": "Transacciones de cartera",
  "p-user-wallet-incoming-internal": "Entrante interno",
  "p-user-wallet-outgoing-internal": "Saliente interno",
  "p-user-wallet-incoming": "Entrante",
  "p-user-wallet-outgoing": "Saliente",
  "p-user-wallet-address-copied": "¡Tu dirección pública ha sido copiada!",
  "p-user-wallet-using-network":
    "Estamos utilizando la red {network} para {currentTab}",
  "p-user-wallet-balance": "Tu saldo es:",
  "p-user-wallet-frozen-tooltip":
    "El monto de los fondos que actualmente están congelados en el sistema en pedidos, transferencias, etc.",
  "p-user-wallet-frozen": "congelado",
  "p-user-wallet-show-address": "Mostrar tu dirección pública {currentTab}",
  "p-user-wallet-copy": "Copiar",
  "p-user-wallet-min-deposit-note-1":
    "Ten en cuenta que tenemos un límite mínimo para depositar criptomonedas.",
  "p-user-wallet-min-deposit-note-2":
    "El límite es de aproximadamente 50 {symbol} equivalente en cripto.",
  "p-user-wallet-current-rate": "Con el tipo de cambio actual:",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Ten en cuenta: No se permiten retiros a menos que la Autenticación de Dos Factores (TFA) esté habilitada en tu cuenta.",
  "p-user-wallet-restriction-alert":
    "Tu cuenta está actualmente restringida para realizar retiros. Por favor, contacta al soporte para obtener más ayuda",
  "p-user-wallet-withdraw": "Retirar {currentTab}",
  "p-user-wallet-history-title": "Historial de transacciones:",
  "p-user-wallet-no-transactions":
    "No se encontraron transacciones para {currentTab}",

  "p-user-head-title": "Página de Usuario | Gestiona tu cuenta de Solid Swap",
  "p-user-head-description":
    "Accede y gestiona la configuración de tu cuenta de Solid Swap, cartera y preferencias personales de manera segura",
  "p-user-head-keywords":
    "configuración de usuario, gestionar cuenta, cartera de cripto, usuario de Solid Swap",
};
