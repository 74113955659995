export default {
  "c-user-avatarChange-avatar-must-be-jpg": "头像图片必须是 JPG 格式！",
  "c-user-avatarChange-avatar-size-exceed": "头像图片大小不能超过 2MB！",
  "c-user-avatarChange-avatar-upload-error": "上传图片时出错！",
  "c-user-avatarChange-change-avatar": "更改头像",
  "c-user-avatarChange-avatar-file-restriction": "jpg/png 文件小于 5MB。",

  "c-user-cookiealert-cookie-preference": "Cookie 偏好",
  "c-user-cookiealert-essential-cookies":
    "我们使用 Cookie 和类似技术来运行我们的网站（必要的 Cookie）。",
  "c-user-cookiealert-additional-cookies":
    "我们还使用分析、功能和定位 Cookie 来分析我们网站的流量，优化您的体验，个性化内容并投放定向广告。",
  "c-user-cookiealert-manage-cookies":
    "您可以随时访问页面底部的管理 Cookie 选项来关闭 Cookie。",
  "c-user-cookiealert-reject-cookies": "拒绝 Cookie",
  "c-user-cookiealert-accept-continue": "接受并继续",

  "c-Enable2FA-enabled": "双重身份验证（2FA）已启用！",
  "c-Enable2FA-disable": "禁用 2FA",
  "c-Enable2FA-not-recommended": "（不推荐）",
  "c-Enable2FA-enable": "启用 2FA",
  "c-Enable2FA-highly-recommended": "（强烈推荐）",
  "c-Enable2FA-enable-2fa": "启用 2FA",
  "c-Enable2FA-scan-image": "使用您的 2FA 应用扫描下方图像以启用双重身份验证",
  "c-Enable2FA-enter-address": "或将此地址输入到您的应用中",
  "c-Enable2FA-enter-code": "输入 2FA 代码",
  "c-Enable2FA-cancel": "取消",
  "c-Enable2FA-error-getting-image": "获取 2FA 图像时出错！",
  "c-Enable2FA-success-enable": "2FA 启用成功！",
  "c-Enable2FA-error-enable": "启用 2FA 时出错！",
  "c-Enable2FA-disable-prompt":
    "您即将禁用账户上的双重身份验证（2FA）。请注意，这会降低您账户的安全性。禁用 2FA 后，您将无法进行交易或提现 24 小时。如果您希望继续，请在下方输入您的 2FA 代码并点击“禁用 2FA”。",
  "c-Enable2FA-warning": "警告",
  "c-Enable2FA-input-error": "输入您的 2FA 代码",
  "c-Enable2FA-success-disable": "2FA 已取消",
  "c-Enable2FA-error-disable": "禁用 2FA 时出错！",

  "c-UserMenu-orders": "订单",
  "c-UserMenu-payment-options": "支付选项",
  "c-UserMenu-wallet": "钱包",
  "c-UserMenu-notifications": "通知",
  "c-UserMenu-settings": "设置",

  "c-NotificationSettings-title": "通知设置",
  "c-NotificationSettings-alert":
    "请注意，通知设置是设备特定的。您可以为每个设备配置不同的设置。",
  "c-NotificationSettings-check-all": "全选",
  "c-NotificationSettings-website-notifications": "网站通知：",
  "c-NotificationSettings-order-notifications": "订单通知",
  "c-NotificationSettings-chat-messages": "聊天消息",
  "c-NotificationSettings-system-notifications": "系统通知：",
  "c-NotificationSettings-system-alert":
    "系统通知是特定于您设备的原生警报。只有在您授予浏览器显示通知权限后，这些通知才会起作用。",

  "c-PasswordChange-change-password": "更改密码",
  "c-PasswordChange-dialog-title": "更改您的密码",
  "c-PasswordChange-description":
    "请输入您当前的密码并选择一个新密码。您的新密码必须满足以下条件：",
  "c-PasswordChange-current-password": "当前密码",
  "c-PasswordChange-new-password": "新密码",
  "c-PasswordChange-confirm-new-password": "确认新密码",
  "c-PasswordChange-min-characters": "最少 6 个字符",
  "c-PasswordChange-max-characters": "最多 20 个字符",
  "c-PasswordChange-2fa-code": "2FA 代码",
  "c-PasswordChange-case-sensitive":
    "密码区分大小写。选择一个强且唯一的密码以确保账户安全。",
  "c-PasswordChange-cancel": "取消",
  "c-PasswordChange-wait-loading": "请稍候，正在加载...",
  "c-PasswordChange-enter-current-password": "请输入您的当前密码",
  "c-PasswordChange-enter-new-password": "请输入您的新密码",
  "c-PasswordChange-please-confirm-new-password": "请确认您的新密码",
  "c-PasswordChange-passwords-not-match": "两次输入的密码不匹配",
  "c-PasswordChange-enter-2fa-code": "请输入您的 2FA 代码",
  "c-PasswordChange-password-changed-success": "密码更改成功！",
  "c-PasswordChange-error-changing-password": "更改密码时出错！",

  "c-TFAAlert-2fa-disabled-title": "您的双重身份验证已禁用",
  "c-TFAAlert-attention": "注意！",
  "c-TFAAlert-message-1":
    "看起来您还没有启用双重身份验证（2FA）。为了您的安全，在 2FA 启用之前，创建订单、报价和提现等操作将被限制。",
  "c-TFAAlert-message-2":
    "您可以继续使用网站，但请考虑启用 2FA 以解锁全部功能并增强账户安全性。",
  "c-TFAAlert-remind-me-later": "稍后提醒我",
  "c-TFAAlert-go-to-setup": "前往 2FA 设置",
};
