export default {
  "p-user-index-welcome": "欢迎来到您的个人仪表板！",

  "p-user-notifications-settings": "前往通知设置",
  "p-user-notifications-all": "所有通知",
  "p-user-notifications-error": "获取通知时出错",
  "p-user-notifications-none": "无通知",

  "p-user-settings-head-title": "用户设置 | 管理您的 Solid Swap 账户",
  "p-user-settings-head-description": "管理您的 Solid Swap 账户设置和偏好。",
  "p-user-settings-title": "用户设置",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "用户名",
  "p-user-settings-last-login": "上次登录",

  "p-user-wallet-head-title": "用户钱包 | 管理您的 Solid Swap 账户",
  "p-user-wallet-title": "钱包交易",
  "p-user-wallet-incoming-internal": "内部收入",
  "p-user-wallet-outgoing-internal": "内部支出",
  "p-user-wallet-incoming": "收入",
  "p-user-wallet-outgoing": "支出",
  "p-user-wallet-address-copied": "您的公共地址已复制！",
  "p-user-wallet-using-network": "我们正在使用 {network} 网络进行 {currentTab}",
  "p-user-wallet-balance": "您的余额为：",
  "p-user-wallet-frozen-tooltip": "当前被冻结的资金金额，涉及订单、转账等。",
  "p-user-wallet-frozen": "冻结",
  "p-user-wallet-show-address": "显示您的公共 {currentTab} 地址",
  "p-user-wallet-copy": "复制",
  "p-user-wallet-min-deposit-note-1": "请注意，我们对加密货币存款有最低限额。",
  "p-user-wallet-min-deposit-note-2": "限额约为 50 {symbol} 等值的加密货币。",
  "p-user-wallet-current-rate": "当前汇率：",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "请注意：除非在您的账户上启用了双重身份验证（TFA），否则不允许提现。",
  "p-user-wallet-restriction-alert":
    "您的账户当前被限制提现。请联系支持以获取进一步帮助",
  "p-user-wallet-withdraw": "提现 {currentTab}",
  "p-user-wallet-history-title": "交易历史：",
  "p-user-wallet-no-transactions": "{currentTab} 无交易记录",

  "p-user-head-title": "用户页面 | 管理您的 Solid Swap 账户",
  "p-user-head-description":
    "安全地访问和管理您的 Solid Swap 账户设置、钱包和个人偏好",
  "p-user-head-keywords": "用户设置，管理账户，加密钱包，Solid Swap 用户",
};
