export default {
  "p-user-index-welcome": "Добро пожаловать в вашу персональную панель!",

  "p-user-notifications-settings": "Перейти к настройкам уведомлений",
  "p-user-notifications-all": "Все уведомления",
  "p-user-notifications-error": "Ошибка при получении уведомлений",
  "p-user-notifications-none": "Нет уведомлений",

  "p-user-settings-head-title":
    "Настройки пользователя | Управляйте вашим аккаунтом Solid Swap",
  "p-user-settings-head-description":
    "Управляйте настройками вашего аккаунта Solid Swap и предпочтениями.",
  "p-user-settings-title": "Настройки пользователя",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Имя пользователя",
  "p-user-settings-last-login": "Последний вход",

  "p-user-wallet-head-title":
    "Кошелек пользователя | Управляйте вашим аккаунтом Solid Swap",
  "p-user-wallet-title": "Транзакции кошелька",
  "p-user-wallet-incoming-internal": "Входящие внутренние",
  "p-user-wallet-outgoing-internal": "Исходящие внутренние",
  "p-user-wallet-incoming": "Входящие",
  "p-user-wallet-outgoing": "Исходящие",
  "p-user-wallet-address-copied": "Ваш публичный адрес был скопирован!",
  "p-user-wallet-using-network":
    "Мы используем сеть {network} для {currentTab}",
  "p-user-wallet-balance": "Ваш баланс:",
  "p-user-wallet-frozen-tooltip":
    "Сумма средств, которые в настоящее время заморожены в системе в заказах, переводах и т.д.",
  "p-user-wallet-frozen": "заморожено",
  "p-user-wallet-show-address": "Показать ваш публичный адрес {currentTab}",
  "p-user-wallet-copy": "Скопировать",
  "p-user-wallet-min-deposit-note-1":
    "Обратите внимание, что у нас есть минимальный лимит для депозита криптовалют.",
  "p-user-wallet-min-deposit-note-2":
    "Лимит составляет около 50 {symbol} в эквиваленте криптовалюты.",
  "p-user-wallet-current-rate": "По текущему курсу обмена:",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Обратите внимание: Вывод средств не разрешен, пока двухфакторная аутентификация (2FA) не включена на вашем аккаунте.",
  "p-user-wallet-restriction-alert":
    "Ваш аккаунт в настоящее время ограничен для вывода средств. Пожалуйста, свяжитесь с поддержкой для получения дополнительной помощи",
  "p-user-wallet-withdraw": "Вывод {currentTab}",
  "p-user-wallet-history-title": "История транзакций:",
  "p-user-wallet-no-transactions": "Транзакции для {currentTab} не найдены",

  "p-user-head-title":
    "Страница пользователя | Управляйте вашим аккаунтом Solid Swap",
  "p-user-head-description":
    "Получите доступ и управляйте настройками вашего аккаунта Solid Swap, кошельком и личными предпочтениями безопасно",
  "p-user-head-keywords":
    "настройки пользователя, управление аккаунтом, крипто кошелек, пользователь Solid Swap",
};
