export default {
  "c-user-avatarChange-avatar-must-be-jpg":
    "Изображение аватара должно быть в формате JPG!",
  "c-user-avatarChange-avatar-size-exceed":
    "Размер изображения аватара не может превышать 2 МБ!",
  "c-user-avatarChange-avatar-upload-error": "Ошибка загрузки изображения!",
  "c-user-avatarChange-change-avatar": "Сменить аватар",
  "c-user-avatarChange-avatar-file-restriction":
    "jpg/png файлы размером менее 5 МБ.",

  "c-user-cookiealert-cookie-preference": "Предпочтения куки",
  "c-user-cookiealert-essential-cookies":
    "Мы используем куки и аналогичные технологии, необходимые для работы наших веб-сайтов (основные куки).",
  "c-user-cookiealert-additional-cookies":
    "Мы также используем аналитические, функциональные и целевые куки для анализа трафика наших веб-сайтов, оптимизации вашего опыта, персонализации контента и показа целевых рекламных объявлений.",
  "c-user-cookiealert-manage-cookies":
    "Вы можете отключить куки в любое время, посетив опцию управления куки внизу страницы.",
  "c-user-cookiealert-reject-cookies": "Отклонить куки",
  "c-user-cookiealert-accept-continue": "Принять и продолжить",

  "c-Enable2FA-enabled": "Двухфакторная аутентификация (2FA) включена!",
  "c-Enable2FA-disable": "Отключить 2FA",
  "c-Enable2FA-not-recommended": "(не рекомендуется)",
  "c-Enable2FA-enable": "Включить 2FA",
  "c-Enable2FA-highly-recommended": "(настоятельно рекомендуется)",
  "c-Enable2FA-enable-2fa": "Включить 2FA",
  "c-Enable2FA-scan-image":
    "Отсканируйте изображение ниже с помощью вашего 2FA приложения, чтобы включить двухфакторную аутентификацию",
  "c-Enable2FA-enter-address": "Или введите этот адрес в вашем приложении",
  "c-Enable2FA-enter-code": "Введите 2FA код",
  "c-Enable2FA-cancel": "Отмена",
  "c-Enable2FA-error-getting-image": "Ошибка получения изображения 2FA!",
  "c-Enable2FA-success-enable": "2FA успешно включена!",
  "c-Enable2FA-error-enable": "Ошибка при включении 2FA!",
  "c-Enable2FA-disable-prompt":
    "Вы собираетесь отключить двухфакторную аутентификацию (2FA) на вашем аккаунте. Пожалуйста, имейте в виду, что это уменьшит безопасность вашего аккаунта. После отключения 2FA вы не сможете торговать или выводить средства в течение 24 часов. Если вы хотите продолжить, введите ваш 2FA код ниже и нажмите 'Отключить 2FA'.",
  "c-Enable2FA-warning": "Предупреждение",
  "c-Enable2FA-input-error": "Введите ваш 2FA код",
  "c-Enable2FA-success-disable": "2FA отключена",
  "c-Enable2FA-error-disable": "Ошибка при отключении 2FA!",

  "c-UserMenu-orders": "Заказы",
  "c-UserMenu-payment-options": "Варианты оплаты",
  "c-UserMenu-wallet": "Кошелек",
  "c-UserMenu-notifications": "Уведомления",
  "c-UserMenu-settings": "Настройки",

  "c-NotificationSettings-title": "Настройки уведомлений",
  "c-NotificationSettings-alert":
    "Обратите внимание, что настройки уведомлений зависят от устройства. Вы можете настроить разные параметры для каждого вашего устройства.",
  "c-NotificationSettings-check-all": "Выбрать все",
  "c-NotificationSettings-website-notifications": "Уведомления на сайте:",
  "c-NotificationSettings-order-notifications": "Уведомления о заказах",
  "c-NotificationSettings-chat-messages": "Сообщения чата",
  "c-NotificationSettings-system-notifications": "Системные уведомления:",
  "c-NotificationSettings-system-alert":
    "Системные уведомления - это нативные оповещения, специфичные для вашего устройства. Эти уведомления будут работать только если вы дали разрешение вашему браузеру показывать уведомления.",

  "c-PasswordChange-change-password": "Сменить пароль",
  "c-PasswordChange-dialog-title": "Сменить ваш пароль",
  "c-PasswordChange-description":
    "Пожалуйста, введите ваш текущий пароль и выберите новый. Ваш новый пароль должен соответствовать следующим критериям:",
  "c-PasswordChange-current-password": "Текущий пароль",
  "c-PasswordChange-new-password": "Новый пароль",
  "c-PasswordChange-confirm-new-password": "Подтвердите новый пароль",
  "c-PasswordChange-min-characters": "Минимум 6 символов",
  "c-PasswordChange-max-characters": "Максимум 20 символов",
  "c-PasswordChange-2fa-code": "2FA код",
  "c-PasswordChange-case-sensitive":
    "Пароли чувствительны к регистру. Выберите надежный, уникальный пароль, чтобы защитить ваш аккаунт.",
  "c-PasswordChange-cancel": "Отмена",
  "c-PasswordChange-wait-loading": "Пожалуйста, подождите, идет загрузка...",
  "c-PasswordChange-enter-current-password":
    "Пожалуйста, введите ваш текущий пароль",
  "c-PasswordChange-enter-new-password": "Пожалуйста, введите ваш новый пароль",
  "c-PasswordChange-please-confirm-new-password":
    "Пожалуйста, подтвердите ваш новый пароль",
  "c-PasswordChange-passwords-not-match": "Пароли не совпадают",
  "c-PasswordChange-enter-2fa-code": "Пожалуйста, введите ваш 2FA код",
  "c-PasswordChange-password-changed-success": "Пароль успешно изменен!",
  "c-PasswordChange-error-changing-password": "Ошибка при смене пароля!",

  "c-TFAAlert-2fa-disabled-title":
    "Ваша двухфакторная аутентификация отключена",
  "c-TFAAlert-attention": "Внимание!",
  "c-TFAAlert-message-1":
    "Похоже, вы еще не включили двухфакторную аутентификацию (2FA). Для вашей безопасности некоторые действия, такие как создание заказов, предложений и вывод средств, ограничены до тех пор, пока 2FA не будет активирована.",
  "c-TFAAlert-message-2":
    "Вы можете продолжить использование сайта, но пожалуйста, рассмотрите возможность включения 2FA, чтобы разблокировать полную функциональность и повысить безопасность вашего аккаунта.",
  "c-TFAAlert-remind-me-later": "Напомнить позже",
  "c-TFAAlert-go-to-setup": "Перейти к настройке 2FA",
};
