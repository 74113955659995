export default {
  "p-faq-head-title":
    "ՀՏՀ | Հաճախակի տրվող հարցեր Solid Swap կրիպտոարժույթի փոխանակման վերաբերյալ",
  "p-faq-head-description":
    "Գտեք պատասխաններ ամենահաճախակի տրվող հարցերին Solid Swap-ի վերաբերյալ: Իմացեք մեր հարթակի, անվտանգության միջոցառումների և ինչպես փոխանակել կրիպտոարժույթ",
  "p-faq-head-keywords":
    "ՀՏՀ, կրիպտոարժույթի փոխանակման հարցեր, Solid Swap ՀՏՀ, կրիպտո առևտրի օգնություն",
  "p-faq-title": "ՀՏՀ Solid Swap-ի վերաբերյալ",
  "p-faq-q1-title": "Ինչ է Solid Swap-ը?",
  "p-faq-q1-answer1":
    "Solid Swap-ը հարթակ է կրիպտոարժույթների և ֆիատ արժույթների արագ փոխանակման համար, որն այժմ տրամադրում է peer-to-peer (P2P) ծառայություններ:",
  "p-faq-q1-answer2":
    "Մենք նախատեսում ենք մոտ ապագայում ներկայացնել ակնթարթային կրիպտոարժույթի փոխանակման հնարավորություն և շարունակել ընդլայնել մեր հարթակի առաջարկած ծառայությունների շրջանակը:",
  "p-faq-q2-title": "Ինչու՞ պետք է վստահեմ Solid Swap-ին?",
  "p-faq-q2-answer1":
    "Դուք կարող եք վստահել Solid Swap-ին, քանի որ մենք պաշտոնապես գրանցված ենք Հայաստանում, ինչը թույլ է տալիս մեզ առաջարկել հարմարավետ պայմաններ մեր օգտագործողներին առանց ID վավերացման և ALM գործարքների մոնիտորինգի կարիքի:",
  "p-faq-q2-answer2":
    "Սա մեզ ազատում է ավանդական բանկի դերից, որը կարող է սառեցնել ձեր գումարները:",
  "p-faq-q2-answer3":
    "Դուք միշտ կարող եք դիտել մեր հաճախորդների կարծիքները այս էջում:",
  "p-faq-q3-title": "Ինչ է Solid Swap-ի առևտրային միջնորդավճարը?",
  "p-faq-q3-answer1":
    "Մենք ձգտում ենք թափանցիկության մեր գործառնությունների բոլոր ասպեկտներում, ներառյալ միջնորդավճարները:",
  "p-faq-q3-answer2":
    "Առևտրային միջնորդավճարը կազմում է գործարքի գումարի միայն 0.2%-ը, որը վճարում է վաճառողը:",
  "p-faq-q3-answer3":
    "Սա թույլ է տալիս ձեզ հեշտությամբ պլանավորել ձեր գործարքները՝ իմանալով, որ անսպասելի ծախսեր չեն լինի:",
  "p-faq-q4-title": "Ո՞ր արժույթները աջակցում է փոխանակման հարթակը:",
  "p-faq-q4-answer":
    "Մեր փոխանակման հարթակը աջակցում է այնպիսի կրիպտոարժույթներ, ինչպիսիք են BTC, ETH, USDT և XMR, և մենք աշխատում ենք ավելացնել ավելի շատ արժույթներ ապագայում:",
  "p-faq-q5-title":
    "Որո՞նք են Solid Swap-ում ավանդների և դուրսբերումների նվազագույն գումարները և արդյո՞ք կան սահմանափակումներ պատվերների չափերի համար:",
  "p-faq-q5-answer1":
    "Solid Swap հարթակում ավանդների և դուրսբերումների նվազագույն գումարը 50 դոլար է:",
  "p-faq-q5-answer2":
    "Եթե ստացվում է այս գումարից պակաս գումար, այն չի հաշվարկվի օգտագործողի հաշվին:",
  "p-faq-q5-answer3":
    "Ինչ վերաբերում է պատվերներին, ապա հարթակը չի սահմանում դրանց չափերի սահմանափակումներ, դրանք որոշվում են գնորդի կամ վաճառողի կողմից:",
  "p-faq-q6-title": "Ինչպես է որոշվում գումարների դուրսբերման արժեքը?",
  "p-faq-q6-answer1":
    "Հաշվից գումարների դուրսբերման արժեքը դինամիկորեն փոխվում է և կախված է ցանցում գործողությունների ընթացիկ արժեքից:",
  "p-faq-q6-answer2":
    "Մենք միշտ աշխատում ենք առաջարկել ձեզ ամենաբարենպաստ պայմանները և նվազեցնել ձեր ծախսերը:",
  "p-faq-q7-title":
    "Ինչպիսի անվտանգության միջոցառումներ են ձեռնարկվում գաղտնաբառը կամ երկուսական նույնականացման կարգավորումները փոխելուց հետո?",
  "p-faq-q7-answer1": "Ձեր անվտանգությունը մեր առաջնահերթությունն է:",
  "p-faq-q7-answer2":
    "Ուստի գաղտնաբառը կամ երկուսական նույնականացման կարգավորումները փոխելուց հետո մենք իրականացնում ենք 24-ժամյա արգելափակում դուրսբերումների համար:",
  "p-faq-q7-answer3":
    "Սա կանխում է չարտոնված հասանելիությունը ձեր գումարներին:",
  "p-faq-q8-title":
    "Ե՞րբ կարող եմ բացել վեճ, եթե դժվարություններ ունեմ վաճառողի հետ?",
  "p-faq-q8-answer1":
    "Եթե չեք կարողանում համաձայնության գալ վաճառողի հետ, կարող եք բացել վեճ 30 րոպե անց խնդրի առաջացումից հետո:",
  "p-faq-q8-answer2":
    "Դրա համար կոճակը ավտոմատ կերպով կհայտնվի ձեր անձնական հաշվին, թույլ տալով արագ լուծել ցանկացած հարց:",
  "p-faq-q9-title": "Ինչքա՞ն ժամանակ է պահանջվում վեճի վերանայման համար?",
  "p-faq-q9-answer1":
    "Մենք ձգտում ենք վերանայել յուրաքանչյուր վեճ հնարավորինս արագ:",
  "p-faq-q9-answer2":
    "Գործընթացը կարող է տևել մինչև 24 ժամ, երբ բոլոր պահանջվող տեղեկատվությունը տրամադրվում է:",
  "p-faq-q9-answer3": "Մեր նպատակն է ձեզ ապահովել արդար և արագ լուծում:",
};
