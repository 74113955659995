export default {
  "p-user-index-welcome": "Willkommen auf Ihrem persönlichen Dashboard!",

  "p-user-notifications-settings":
    "Zu den Benachrichtigungseinstellungen gehen",
  "p-user-notifications-all": "Alle Benachrichtigungen",
  "p-user-notifications-error": "Fehler beim Abrufen der Benachrichtigungen",
  "p-user-notifications-none": "Keine Benachrichtigungen",

  "p-user-settings-head-title":
    "Benutzereinstellungen | Verwalten Sie Ihr Solid Swap-Konto",
  "p-user-settings-head-description":
    "Verwalten Sie die Einstellungen und Präferenzen Ihres Solid Swap-Kontos.",
  "p-user-settings-title": "Benutzereinstellungen",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Benutzername",
  "p-user-settings-last-login": "Letzte Anmeldung",

  "p-user-wallet-head-title":
    "Benutzer-Wallet | Verwalten Sie Ihr Solid Swap-Konto",
  "p-user-wallet-title": "Wallet-Transaktionen",
  "p-user-wallet-incoming-internal": "Eingehende interne",
  "p-user-wallet-outgoing-internal": "Ausgehende interne",
  "p-user-wallet-incoming": "Eingehend",
  "p-user-wallet-outgoing": "Ausgehend",
  "p-user-wallet-address-copied": "Ihre öffentliche Adresse wurde kopiert!",
  "p-user-wallet-using-network":
    "Wir verwenden das {network} Netzwerk für {currentTab}",
  "p-user-wallet-balance": "Ihr Guthaben beträgt:",
  "p-user-wallet-frozen-tooltip":
    "Der Betrag der Gelder, die derzeit im System in Aufträgen, Überweisungen usw. eingefroren sind.",
  "p-user-wallet-frozen": "eingefroren",
  "p-user-wallet-show-address":
    "Zeigen Sie Ihre öffentliche {currentTab} Adresse",
  "p-user-wallet-copy": "Kopieren",
  "p-user-wallet-min-deposit-note-1":
    "Beachten Sie, dass wir ein Mindestlimit für die Einzahlung von Kryptowährungen haben.",
  "p-user-wallet-min-deposit-note-2":
    "Das Limit beträgt etwa 50 {symbol} Gegenwert in Krypto.",
  "p-user-wallet-current-rate": "Mit aktuellem Wechselkurs:",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Bitte beachten Sie: Abhebungen sind nur zulässig, wenn die Zwei-Faktor-Authentifizierung (TFA) auf Ihrem Konto aktiviert ist.",
  "p-user-wallet-restriction-alert":
    "Ihr Konto ist derzeit für Abhebungen eingeschränkt. Bitte kontaktieren Sie den Support für weitere Unterstützung",
  "p-user-wallet-withdraw": "Abheben {currentTab}",
  "p-user-wallet-history-title": "Transaktionshistorie:",
  "p-user-wallet-no-transactions":
    "Keine Transaktionen gefunden für {currentTab}",

  "p-user-head-title": "Benutzerseite | Verwalten Sie Ihr Solid Swap-Konto",
  "p-user-head-description":
    "Greifen Sie sicher auf Ihre Solid Swap-Kontoeinstellungen, Wallet und persönlichen Vorlieben zu und verwalten Sie diese",
  "p-user-head-keywords":
    "benutzereinstellungen, konto verwalten, krypto-wallet, Solid Swap benutzer",
};
