export default {
  "p-faq-head-title":
    "FAQ | Common Questions About Solid Swap Cryptocurrency Exchange",
  "p-faq-head-description":
    "Find answers to the most frequently asked questions about Solid Swap. Learn about our platform, security measures, and how to trade cryptocurrency",
  "p-faq-head-keywords":
    "FAQ, cryptocurrency exchange questions, Solid Swap FAQs, crypto trading help",
  "p-faq-title": "FAQ about Solid Swap",
  "p-faq-q1-title": "What is Solid Swap?",
  "p-faq-q1-answer1":
    "Solid Swap is a platform for fast exchange of cryptocurrencies and fiat currencies, currently providing peer-to-peer (P2P) services.",
  "p-faq-q1-answer2":
    "We plan to introduce the ability for instant cryptocurrency exchange in the near future and continue to expand the range of services offered by our platform.",
  "p-faq-q2-title": "Why should I trust Solid Swap?",
  "p-faq-q2-answer1":
    "You can trust Solid Swap because we are officially registered in Armenia, which allows us to offer convenient conditions to our users without the need for ID verification and ALM transaction monitoring.",
  "p-faq-q2-answer2":
    "This frees us from the role of a traditional bank, which could freeze your funds.",
  "p-faq-q2-answer3":
    "You can always review the feedback from our customers on this page.",
  "p-faq-q3-title": "What is the trading commission on Solid Swap?",
  "p-faq-q3-answer1":
    "We strive for transparency in all aspects of our operations, including fees.",
  "p-faq-q3-answer2":
    "The trading commission is only 0.2% of the transaction amount, which is paid by the seller.",
  "p-faq-q3-answer3":
    "This allows you to easily plan your transactions, knowing that there will be no unexpected fees.",
  "p-faq-q4-title": "Which currencies does the exchange support for trading?",
  "p-faq-q4-answer":
    "Our exchange supports cryptocurrencies such as BTC, ETH, USDT, and XMR, and is working on adding more currencies in the future.",
  "p-faq-q5-title":
    "What are the minimum amounts for deposits and withdrawals on Solid Swap, and are there any restrictions on order sizes?",
  "p-faq-q5-answer1":
    "The minimum amount for deposits and withdrawals on the Solid Swap platform is 50 dollars.",
  "p-faq-q5-answer2":
    "If an amount less than this is received, it will not be credited to the user's account.",
  "p-faq-q5-answer3":
    "As for orders, the platform does not set limits on their sizes—these are determined by the buyer or seller themselves.",
  "p-faq-q6-title": "How is the cost of withdrawing funds determined?",
  "p-faq-q6-answer1":
    "The cost of withdrawing money from an account changes dynamically and depends on the current cost of transactions in the network.",
  "p-faq-q6-answer2":
    "We always work to offer you the most favorable conditions and minimize your expenses.",
  "p-faq-q7-title":
    "What security measures are taken after changing the password or two-factor authentication settings?",
  "p-faq-q7-answer1": "Your security is our priority.",
  "p-faq-q7-answer2":
    "Therefore, after changing your password or two-factor authentication settings, we implement a 24-hour blocking period for withdrawals.",
  "p-faq-q7-answer3": "This prevents unauthorized access to your funds.",
  "p-faq-q8-title":
    "When can I open a dispute if there are difficulties with the seller?",
  "p-faq-q8-answer1":
    "If you are unable to reach an agreement with the seller, you can open a dispute 30 minutes after the problem arises.",
  "p-faq-q8-answer2":
    "A button for this will automatically appear in your personal account, allowing you to quickly resolve any issue.",
  "p-faq-q9-title": "How long will it take to review a dispute?",
  "p-faq-q9-answer1":
    "We strive to review each dispute as quickly as possible.",
  "p-faq-q9-answer2":
    "The process can take up to 24 hours, as soon as all the requested information is provided.",
  "p-faq-q9-answer3":
    "Our goal is to provide you with a fair and swift resolution.",
};
