export default {
  "p-about-head-title":
    "À propos de Solid Swap | Rencontrez notre équipe d'échange de cryptomonnaies",
  "p-about-head-description":
    "En savoir plus sur Solid Swap, l'équipe derrière notre plateforme de trading de cryptomonnaies sécurisée, et notre mission de fournir des échanges d'actifs numériques efficaces",
  "p-about-head-keywords":
    "échange de cryptomonnaies, trading sécurisé, actifs numériques, trading de crypto, Solid Swap, solidswap",
  "p-about-title":
    "Solid Swap : Non seulement une plateforme d'échange de crypto, mais votre chemin vers la liberté financière.",
  "p-about-why-choose-us-title": "Pourquoi nous choisir ?",
  "p-about-fast-secure-title": "Transactions rapides et sécurisées",
  "p-about-fast-secure-description":
    "Nous valorisons votre temps et votre vie privée, offrant des transactions instantanées avec une sécurité maximale.",
  "p-about-support-title": "Support 24/7",
  "p-about-support-description":
    "Notre mission est de travailler sans relâche, 24/7, pour affirmer quotidiennement notre réputation impeccable et la confiance de nos clients.",
  "p-about-conditions-title": "Conditions avantageuses",
  "p-about-conditions-description":
    "Notre objectif est de rendre les transactions de cryptomonnaies rapides, sécurisées et rentables pour chaque utilisateur.",
  "p-about-what-makes-us-unique-title": "Ce qui nous rend uniques ?",
  "p-about-freedom-anonymity-title": "Liberté et Anonymat",
  "p-about-freedom-anonymity-description":
    "Contrairement à de nombreuses plateformes qui évoluent en structures bancaires et ne peuvent garantir une sécurité absolue, Solid Swap maintient les principes de liberté et d'anonymat. Nous soutenons les objectifs originaux des cryptomonnaies et offrons un espace sûr pour vos transactions.",
  "p-about-registration-title": "Enregistrement en Arménie",
  "p-about-registration-description":
    "Notre plateforme est enregistrée dans un pays avec une législation libérale sur les cryptomonnaies, nous permettant d'opérer dans la légalité tout en maintenant notre indépendance et nos principes.",
  "p-about-ease-title": "Facilité d'utilisation",
  "p-about-ease-description":
    "Notre plateforme est optimisée pour une utilisation sur tous les appareils, garantissant un échange de cryptomonnaies pratique et efficace.",
  "p-about-community-title": "Communauté forte",
  "p-about-community-description":
    "Solid Swap n'est pas seulement une plateforme, mais une communauté où chaque membre contribue à la liberté économique et au bien-être collectif. Ensemble, nous créons un écosystème qui libère le potentiel économique de chaque participant.",
  "p-about-subscribe-title": "Abonnez-vous à Solid Swap",
  "p-about-contact-title": "Contactez-nous",
  "p-about-address": "Adresse :",
  "p-about-index": "Indice :",
  "p-about-tax-id": "Identifiant fiscal :",
  "p-about-email": "Email :",
  "p-about-support-email": "Email de support :",
  "p-about-duns": "Système universel de numérotation des données :",
  "p-about-duns-check": "Vérifier le D-U-N-S",
};
