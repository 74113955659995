export default {
  "p-about-head-title":
    "Über Solid Swap | Lernen Sie unser Kryptowährungsbörse-Team kennen",
  "p-about-head-description":
    "Erfahren Sie mehr über Solid Swap, das Team hinter unserer sicheren Kryptowährungshandelsplattform, und unsere Mission, effiziente digitale Asset-Swaps anzubieten",
  "p-about-head-keywords":
    "kryptowährungsbörse, sicherer handel, digitale vermögenswerte, krypto-handel, Solid Swap, solidswap",
  "p-about-title":
    "Solid Swap: Nicht nur eine Krypto-Börse, sondern Ihr Weg zur finanziellen Freiheit.",
  "p-about-why-choose-us-title": "Warum uns wählen?",
  "p-about-fast-secure-title": "Schnelle und sichere Transaktionen",
  "p-about-fast-secure-description":
    "Wir schätzen Ihre Zeit und Privatsphäre und bieten sofortige Transaktionen mit maximaler Sicherheit.",
  "p-about-support-title": "24/7 Support",
  "p-about-support-description":
    "Unsere Mission ist es, unermüdlich, 24/7, täglich unseren tadellosen Ruf und das Vertrauen unserer Kunden zu bestätigen.",
  "p-about-conditions-title": "Vorteilhafte Bedingungen",
  "p-about-conditions-description":
    "Unser Ziel ist es, Kryptowährungstransaktionen schnell, sicher und profitabel für jeden Benutzer zu machen.",
  "p-about-what-makes-us-unique-title": "Was macht uns einzigartig?",
  "p-about-freedom-anonymity-title": "Freiheit und Anonymität",
  "p-about-freedom-anonymity-description":
    "Im Gegensatz zu vielen Plattformen, die sich zu Bankstrukturen entwickeln und keine absolute Sicherheit garantieren können, hält Solid Swap an den Prinzipien der Freiheit und Anonymität fest. Wir unterstützen die ursprünglichen Ziele der Kryptowährung und bieten einen sicheren Raum für Ihre Transaktionen.",
  "p-about-registration-title": "Registrierung in Armenien",
  "p-about-registration-description":
    "Unsere Plattform ist in einem Land mit liberaler Kryptowährungsgesetzgebung registriert, sodass wir innerhalb des Gesetzes operieren und gleichzeitig unsere Unabhängigkeit und Prinzipien bewahren können.",
  "p-about-ease-title": "Benutzerfreundlichkeit",
  "p-about-ease-description":
    "Unsere Plattform ist für die Nutzung auf allen Geräten optimiert und sorgt für einen bequemen und effizienten Kryptowährungsaustausch.",
  "p-about-community-title": "Starke Gemeinschaft",
  "p-about-community-description":
    "Solid Swap ist nicht nur eine Plattform, sondern eine Gemeinschaft, in der jedes Mitglied zur wirtschaftlichen Freiheit und zum kollektiven Wohlstand beiträgt. Gemeinsam schaffen wir ein Ökosystem, das das wirtschaftliche Potenzial jedes Teilnehmers freisetzt.",
  "p-about-subscribe-title": "Abonnieren Sie Solid Swap",
  "p-about-contact-title": "Kontaktieren Sie uns",
  "p-about-address": "Adresse:",
  "p-about-index": "Index:",
  "p-about-tax-id": "STEUER-ID:",
  "p-about-email": "E-Mail:",
  "p-about-support-email": "Support-E-Mail:",
  "p-about-duns": "Data Universal Numbering System:",
  "p-about-duns-check": "D-U-N-S überprüfen",
};
