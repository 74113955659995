export default {
  "p-about-head-title": "关于 Solid Swap | 了解我们的加密货币交易团队",
  "p-about-head-description":
    "了解 Solid Swap、我们的安全加密货币交易平台背后的团队，以及我们提供高效数字资产交换的使命",
  "p-about-head-keywords":
    "加密货币交易所，安全交易，数字资产，加密交易，Solid Swap，solidswap",
  "p-about-title": "Solid Swap：不仅是一个加密交易所，而是您的金融自由之路。",
  "p-about-why-choose-us-title": "为什么选择我们？",
  "p-about-fast-secure-title": "快速安全的交易",
  "p-about-fast-secure-description":
    "我们重视您的时间和隐私，提供即时交易和最高安全性。",
  "p-about-support-title": "24/7 支持",
  "p-about-support-description":
    "我们的使命是日夜不停地工作，24/7，来每日确认我们无可挑剔的声誉和客户的信任。",
  "p-about-conditions-title": "有利条件",
  "p-about-conditions-description":
    "我们的目标是让每位用户的加密货币交易快速、安全且有利可图。",
  "p-about-what-makes-us-unique-title": "是什么让我们与众不同？",
  "p-about-freedom-anonymity-title": "自由与匿名",
  "p-about-freedom-anonymity-description":
    "与许多演变成银行结构且无法保证绝对安全的平台不同，Solid Swap 保持自由和匿名的原则。我们支持加密货币的初衷，为您的交易提供安全空间。",
  "p-about-registration-title": "在亚美尼亚注册",
  "p-about-registration-description":
    "我们的平台在一个具有自由加密货币立法的国家注册，使我们在遵守法律的同时保持独立性和原则。",
  "p-about-ease-title": "易用性",
  "p-about-ease-description":
    "我们的平台针对所有设备进行了优化，确保便捷高效的加密货币交易。",
  "p-about-community-title": "强大的社区",
  "p-about-community-description":
    "Solid Swap 不仅是一个平台，更是一个社区，每个成员都为经济自由和集体福祉做出贡献。我们共同创建了一个释放每个参与者经济潜力的生态系统。",
  "p-about-subscribe-title": "订阅 Solid Swap",
  "p-about-contact-title": "联系我们",
  "p-about-address": "地址：",
  "p-about-index": "邮政编码：",
  "p-about-tax-id": "税号：",
  "p-about-email": "邮箱：",
  "p-about-support-email": "支持邮箱：",
  "p-about-duns": "数据通用编号系统：",
  "p-about-duns-check": "检查 D-U-N-S",
};
