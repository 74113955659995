export default {
  "p-user-index-welcome": "Bienvenue sur votre tableau de bord personnel !",

  "p-user-notifications-settings": "Aller aux paramètres de notification",
  "p-user-notifications-all": "Toutes les notifications",
  "p-user-notifications-error":
    "Erreur lors de la récupération des notifications",
  "p-user-notifications-none": "Aucune notification",

  "p-user-settings-head-title":
    "Paramètres utilisateur | Gérez votre compte Solid Swap",
  "p-user-settings-head-description":
    "Gérez les paramètres de votre compte Solid Swap et vos préférences.",
  "p-user-settings-title": "Paramètres utilisateur",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Nom d'utilisateur",
  "p-user-settings-last-login": "Dernière connexion",

  "p-user-wallet-head-title":
    "Portefeuille utilisateur | Gérez votre compte Solid Swap",
  "p-user-wallet-title": "Transactions de portefeuille",
  "p-user-wallet-incoming-internal": "Entrées internes",
  "p-user-wallet-outgoing-internal": "Sorties internes",
  "p-user-wallet-incoming": "Entrées",
  "p-user-wallet-outgoing": "Sorties",
  "p-user-wallet-address-copied": "Votre adresse publique a été copiée !",
  "p-user-wallet-using-network":
    "Nous utilisons le réseau {network} pour {currentTab}",
  "p-user-wallet-balance": "Votre solde est de :",
  "p-user-wallet-frozen-tooltip":
    "Le montant des fonds actuellement gelés dans le système, dans les commandes, les transferts, etc.",
  "p-user-wallet-frozen": "gelé",
  "p-user-wallet-show-address": "Afficher votre adresse publique {currentTab}",
  "p-user-wallet-copy": "Copier",
  "p-user-wallet-min-deposit-note-1":
    "Notez que nous avons une limite minimale pour déposer des cryptomonnaies.",
  "p-user-wallet-min-deposit-note-2":
    "La limite est d'environ 50 {symbol} équivalent en crypto.",
  "p-user-wallet-current-rate": "Au taux de change actuel :",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Veuillez noter : Les retraits ne sont pas autorisés à moins que l'authentification à deux facteurs (TFA) ne soit activée sur votre compte.",
  "p-user-wallet-restriction-alert":
    "Votre compte est actuellement restreint pour les retraits. Veuillez contacter le support pour plus d'assistance",
  "p-user-wallet-withdraw": "Retirer {currentTab}",
  "p-user-wallet-history-title": "Historique des transactions :",
  "p-user-wallet-no-transactions":
    "Aucune transaction trouvée pour {currentTab}",

  "p-user-head-title": "Page utilisateur | Gérez votre compte Solid Swap",
  "p-user-head-description":
    "Accédez et gérez les paramètres de votre compte Solid Swap, votre portefeuille et vos préférences personnelles en toute sécurité",
  "p-user-head-keywords":
    "paramètres utilisateur, gestion de compte, portefeuille crypto, utilisateur Solid Swap",
};
