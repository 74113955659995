export default {
  "p-about-head-title":
    "Acerca de Solid Swap | Conoce a nuestro equipo de intercambio de criptomonedas",
  "p-about-head-description":
    "Aprende más sobre Solid Swap, el equipo detrás de nuestra plataforma segura de comercio de criptomonedas, y nuestra misión de proporcionar intercambios eficientes de activos digitales",
  "p-about-head-keywords":
    "intercambio de criptomonedas, comercio seguro, activos digitales, comercio de cripto, Solid Swap, solidswap",
  "p-about-title":
    "Solid Swap: No solo un intercambio de cripto, sino tu camino hacia la libertad financiera.",
  "p-about-why-choose-us-title": "¿Por qué elegirnos?",
  "p-about-fast-secure-title": "Transacciones rápidas y seguras",
  "p-about-fast-secure-description":
    "Valoramos tu tiempo y privacidad, ofreciendo transacciones instantáneas con máxima seguridad.",
  "p-about-support-title": "Soporte 24/7",
  "p-about-support-description":
    "Nuestra misión es trabajar incansablemente, 24/7, para afirmar diariamente nuestra impecable reputación y la confianza de nuestros clientes.",
  "p-about-conditions-title": "Condiciones ventajosas",
  "p-about-conditions-description":
    "Nuestro objetivo es hacer que las transacciones de criptomonedas sean rápidas, seguras y rentables para cada usuario.",
  "p-about-what-makes-us-unique-title": "¿Qué nos hace únicos?",
  "p-about-freedom-anonymity-title": "Libertad y anonimato",
  "p-about-freedom-anonymity-description":
    "A diferencia de muchas plataformas que evolucionan hacia estructuras bancarias y no pueden garantizar la seguridad absoluta, Solid Swap mantiene principios de libertad y anonimato. Apoyamos los objetivos originales de la criptomoneda y proporcionamos un espacio seguro para tus transacciones.",
  "p-about-registration-title": "Registro en Armenia",
  "p-about-registration-description":
    "Nuestra plataforma está registrada en un país con legislación liberal sobre criptomonedas, lo que nos permite operar dentro de la ley mientras mantenemos nuestra independencia y principios.",
  "p-about-ease-title": "Facilidad de uso",
  "p-about-ease-description":
    "Nuestra plataforma está optimizada para su uso en todos los dispositivos, asegurando un intercambio de criptomonedas conveniente y eficiente.",
  "p-about-community-title": "Comunidad fuerte",
  "p-about-community-description":
    "Solid Swap no es solo una plataforma, sino una comunidad donde cada miembro contribuye a la libertad económica y el bienestar colectivo. Juntos, creamos un ecosistema que desbloquea el potencial económico para cada participante.",
  "p-about-subscribe-title": "Suscríbete a Solid Swap",
  "p-about-contact-title": "Contáctanos",
  "p-about-address": "Dirección:",
  "p-about-index": "Índice:",
  "p-about-tax-id": "ID de Impuestos:",
  "p-about-email": "Correo electrónico:",
  "p-about-support-email": "Correo de soporte:",
  "p-about-duns": "Sistema Universal de Numeración de Datos:",
  "p-about-duns-check": "Verificar D-U-N-S",
};
