export default {
  "p-user-index-welcome": "Բարի գալուստ ձեր անձնական վահանակին!",

  "p-user-notifications-settings": "Գնալ ծանուցումների կարգավորումներ",
  "p-user-notifications-all": "Բոլոր ծանուցումները",
  "p-user-notifications-error": "Ծանուցումների ստացման սխալ",
  "p-user-notifications-none": "Ծանուցումներ չկան",

  "p-user-settings-head-title":
    "Օգտատիրոջ կարգավորումներ | Կառավարեք Ձեր Solid Swap Հաշիվը",
  "p-user-settings-head-description":
    "Կառավարեք ձեր Solid Swap հաշվի կարգավորումները և նախընտրությունները:",
  "p-user-settings-title": "Օգտատիրոջ կարգավորումներ",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Օգտանուն",
  "p-user-settings-last-login": "Վերջին մուտք",

  "p-user-wallet-head-title":
    "Օգտատիրոջ դրամապանակ | Կառավարեք Ձեր Solid Swap Հաշիվը",
  "p-user-wallet-title": "Դրամապանակի գործարքներ",
  "p-user-wallet-incoming-internal": "Ներսից ներսում եկած",
  "p-user-wallet-outgoing-internal": "Ներսից դուրս եկած",
  "p-user-wallet-incoming": "Ներսում եկած",
  "p-user-wallet-outgoing": "Դուրսում եկած",
  "p-user-wallet-address-copied": "Ձեր հանրային հասցեն պատճենվել է!",
  "p-user-wallet-using-network":
    "Մենք օգտագործում ենք {network} ցանցը {currentTab}-ի համար",
  "p-user-wallet-balance": "Ձեր մնացորդը:",
  "p-user-wallet-frozen-tooltip":
    "Ֆոնդերի քանակը, որոնք ներկայումս սառեցված են համակարգում պատվերներում, փոխանցումներում և այլն:",
  "p-user-wallet-frozen": "սառեցված",
  "p-user-wallet-show-address": "Ցույց տալ ձեր հանրային {currentTab} հասցեն",
  "p-user-wallet-copy": "Պատճենել",
  "p-user-wallet-min-deposit-note-1":
    "Նշեք, որ մենք ունենք կրիպտոարժույթների մուտքի նվազագույն սահման:",
  "p-user-wallet-min-deposit-note-2":
    "Սահմանը մոտ 50 {symbol} համարժեք կրիպտոյում:",
  "p-user-wallet-current-rate": "Ընթացիկ փոխարժեքով:",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Խնդրում ենք նկատի ունենալ: Ելքերը չեն թույլատրվում, եթե Երկու-ֆակտորային նույնականացումը (TFA) ակտիվացված չէ ձեր հաշվի վրա:",
  "p-user-wallet-restriction-alert":
    "Ձեր հաշիվը ներկայումս սահմանափակված է ելքերի գործառույթից: Խնդրում ենք դիմել աջակցությանը՝ հետագա օգնության համար",
  "p-user-wallet-withdraw": "Ելք {currentTab}",
  "p-user-wallet-history-title": "Գործարքների պատմություն:",
  "p-user-wallet-no-transactions": "Գործարքներ չեն գտնվել {currentTab}-ի համար",

  "p-user-head-title": "Օգտատեր էջ | Կառավարեք ձեր Solid Swap հաշիվը",
  "p-user-head-description":
    "Անվտանգ հասանելիություն և կառավարում ձեր Solid Swap հաշվի կարգավորումների, դրամապանակի և անձնական նախընտրությունների",
  "p-user-head-keywords":
    "օգտատեր կարգավորումներ, հաշվի կառավարում, կրիպտո դրամապանակ, Solid Swap օգտատեր",
};
