export default {
  "p-faq-head-title":
    "FAQ | Häufig gestellte Fragen zur Kryptowährungsbörse Solid Swap",
  "p-faq-head-description":
    "Finden Sie Antworten auf die am häufigsten gestellten Fragen zu Solid Swap. Erfahren Sie mehr über unsere Plattform, Sicherheitsmaßnahmen und wie Sie mit Kryptowährungen handeln",
  "p-faq-head-keywords":
    "FAQ, fragen zur kryptowährungsbörse, Solid Swap FAQs, krypto-handelsunterstützung",
  "p-faq-title": "FAQ zu Solid Swap",
  "p-faq-q1-title": "Was ist Solid Swap?",
  "p-faq-q1-answer1":
    "Solid Swap ist eine Plattform für den schnellen Austausch von Kryptowährungen und Fiat-Währungen, die derzeit Peer-to-Peer (P2P)-Dienste anbietet.",
  "p-faq-q1-answer2":
    "Wir planen, in naher Zukunft die Möglichkeit eines sofortigen Kryptowährungsaustauschs einzuführen und das Dienstleistungsangebot unserer Plattform weiter auszubauen.",
  "p-faq-q2-title": "Warum sollte ich Solid Swap vertrauen?",
  "p-faq-q2-answer1":
    "Sie können Solid Swap vertrauen, weil wir offiziell in Armenien registriert sind, was es uns ermöglicht, unseren Benutzern bequeme Bedingungen ohne ID-Überprüfung und ALM-Transaktionsüberwachung anzubieten.",
  "p-faq-q2-answer2":
    "Dies befreit uns von der Rolle einer traditionellen Bank, die Ihre Gelder einfrieren könnte.",
  "p-faq-q2-answer3":
    "Sie können das Feedback unserer Kunden auf dieser Seite jederzeit einsehen.",
  "p-faq-q3-title": "Was ist die Handelskommission bei Solid Swap?",
  "p-faq-q3-answer1":
    "Wir streben nach Transparenz in allen Aspekten unserer Arbeit, einschließlich der Gebühren.",
  "p-faq-q3-answer2":
    "Die Handelskommission beträgt nur 0,2% des Transaktionsbetrags, die der Verkäufer zahlt.",
  "p-faq-q3-answer3":
    "Dadurch können Sie Ihre Transaktionen einfach planen, da keine unerwarteten Gebühren anfallen.",
  "p-faq-q4-title": "Welche Währungen unterstützt die Börse für den Handel?",
  "p-faq-q4-answer":
    "Unsere Börse unterstützt Kryptowährungen wie BTC, ETH, USDT und XMR und arbeitet daran, in Zukunft weitere Währungen hinzuzufügen.",
  "p-faq-q5-title":
    "Was sind die Mindestbeträge für Einzahlungen und Abhebungen auf Solid Swap und gibt es Beschränkungen für Auftragsgrößen?",
  "p-faq-q5-answer1":
    "Der Mindestbetrag für Einzahlungen und Abhebungen auf der Solid Swap-Plattform beträgt 50 Dollar.",
  "p-faq-q5-answer2":
    "Wenn ein Betrag kleiner als dieser eingeht, wird er nicht dem Benutzerkonto gutgeschrieben.",
  "p-faq-q5-answer3":
    "Was die Aufträge betrifft, so setzt die Plattform keine Grenzen für deren Größen – diese werden vom Käufer oder Verkäufer selbst bestimmt.",
  "p-faq-q6-title": "Wie wird die Kosten für Abhebungen bestimmt?",
  "p-faq-q6-answer1":
    "Die Kosten für das Abheben von Geldern von einem Konto ändern sich dynamisch und hängen von den aktuellen Transaktionskosten im Netzwerk ab.",
  "p-faq-q6-answer2":
    "Wir arbeiten stets daran, Ihnen die günstigsten Bedingungen zu bieten und Ihre Ausgaben zu minimieren.",
  "p-faq-q7-title":
    "Welche Sicherheitsmaßnahmen werden nach dem Ändern des Passworts oder der Zwei-Faktor-Authentifizierungseinstellungen getroffen?",
  "p-faq-q7-answer1": "Ihre Sicherheit hat für uns oberste Priorität.",
  "p-faq-q7-answer2":
    "Daher implementieren wir nach der Änderung Ihres Passworts oder der Zwei-Faktor-Authentifizierungseinstellungen eine 24-Stunden-Sperrfrist für Abhebungen.",
  "p-faq-q7-answer3": "Dies verhindert den unbefugten Zugriff auf Ihre Gelder.",
  "p-faq-q8-title":
    "Wann kann ich einen Streitfall eröffnen, wenn es Schwierigkeiten mit dem Verkäufer gibt?",
  "p-faq-q8-answer1":
    "Wenn Sie keine Einigung mit dem Verkäufer erzielen können, können Sie 30 Minuten nach Auftreten des Problems einen Streitfall eröffnen.",
  "p-faq-q8-answer2":
    "Eine Schaltfläche dafür wird automatisch in Ihrem persönlichen Konto angezeigt, sodass Sie schnell jedes Problem lösen können.",
  "p-faq-q9-title": "Wie lange dauert die Prüfung eines Streitfalls?",
  "p-faq-q9-answer1":
    "Wir bemühen uns, jeden Streitfall so schnell wie möglich zu prüfen.",
  "p-faq-q9-answer2":
    "Der Prozess kann bis zu 24 Stunden dauern, sobald alle angeforderten Informationen bereitgestellt wurden.",
  "p-faq-q9-answer3":
    "Unser Ziel ist es, Ihnen eine faire und schnelle Lösung zu bieten.",
};
