export default {
  "p-about-head-title":
    "О Solid Swap | Познакомьтесь с нашей командой криптовалютной биржи",
  "p-about-head-description":
    "Узнайте больше о Solid Swap, команде, стоящей за нашей безопасной платформой для торговли криптовалютами, и нашей миссии по обеспечению эффективных обменов цифровыми активами",
  "p-about-head-keywords":
    "криптовалютная биржа, безопасная торговля, цифровые активы, торговля криптовалютой, Solid Swap, solidswap",
  "p-about-title":
    "Solid Swap: Не просто криптовалютная биржа, а ваш путь к финансовой свободе.",
  "p-about-why-choose-us-title": "Почему выбирают нас?",
  "p-about-fast-secure-title": "Быстрые и безопасные транзакции",
  "p-about-fast-secure-description":
    "Мы ценим ваше время и конфиденциальность, предлагая мгновенные транзакции с максимальной безопасностью.",
  "p-about-support-title": "Круглосуточная поддержка",
  "p-about-support-description":
    "Наша миссия - работать неустанно, 24/7, чтобы ежедневно подтверждать нашу безупречную репутацию и доверие наших клиентов.",
  "p-about-conditions-title": "Выгодные условия",
  "p-about-conditions-description":
    "Наша цель - сделать операции с криптовалютой быстрыми, безопасными и выгодными для каждого пользователя.",
  "p-about-what-makes-us-unique-title": "Что делает нас уникальными?",
  "p-about-freedom-anonymity-title": "Свобода и анонимность",
  "p-about-freedom-anonymity-description":
    "В отличие от многих платформ, которые превращаются в банковские структуры и не могут гарантировать абсолютную безопасность, Solid Swap придерживается принципов свободы и анонимности. Мы поддерживаем исходные цели криптовалют и предоставляем безопасное пространство для ваших транзакций.",
  "p-about-registration-title": "Регистрация в Армении",
  "p-about-registration-description":
    "Наша платформа зарегистрирована в стране с либеральным законодательством в области криптовалют, что позволяет нам работать в рамках закона, сохраняя нашу независимость и принципы.",
  "p-about-ease-title": "Простота использования",
  "p-about-ease-description":
    "Наша платформа оптимизирована для использования на всех устройствах, обеспечивая удобный и эффективный обмен криптовалютами.",
  "p-about-community-title": "Сильное сообщество",
  "p-about-community-description":
    "Solid Swap - это не просто платформа, а сообщество, где каждый участник вносит вклад в экономическую свободу и коллективное благополучие. Вместе мы создаем экосистему, которая раскрывает экономический потенциал каждого участника.",
  "p-about-subscribe-title": "Подписаться на Solid Swap",
  "p-about-contact-title": "Свяжитесь с нами",
  "p-about-address": "Адрес:",
  "p-about-index": "Индекс:",
  "p-about-tax-id": "ИНН:",
  "p-about-email": "Электронная почта:",
  "p-about-support-email": "Электронная почта поддержки:",
  "p-about-duns": "Data Universal Numbering System:",
  "p-about-duns-check": "Проверить D-U-N-S",
};
