export default {
  "p-about-head-title":
    "About Solid Swap | Meet Our Cryptocurrency Exchange Team",
  "p-about-head-description":
    "Learn more about Solid Swap, the team behind our secure cryptocurrency trading platform, and our mission to provide efficient digital asset swaps",
  "p-about-head-keywords":
    "cryptocurrency exchange, secure trading, digital assets, crypto trading, Solid Swap, solidswap",
  "p-about-title":
    "Solid Swap: Not just a crypto exchange, but your path to financial freedom.",
  "p-about-why-choose-us-title": "Why choose us?",
  "p-about-fast-secure-title": "Fast and Secure Transactions",
  "p-about-fast-secure-description":
    "We value your time and privacy, offering instant transactions with maximum security.",
  "p-about-support-title": "24/7 Support",
  "p-about-support-description":
    "Our mission is to work tirelessly, 24/7, to daily affirm our impeccable reputation and the trust of our clients.",
  "p-about-conditions-title": "Advantageous Conditions",
  "p-about-conditions-description":
    "Our goal is to make cryptocurrency transactions fast, secure, and profitable for every user.",
  "p-about-what-makes-us-unique-title": "What makes us unique?",
  "p-about-freedom-anonymity-title": "Freedom and Anonymity",
  "p-about-freedom-anonymity-description":
    "Unlike many platforms that evolve into banking structures and cannot guarantee absolute security, Solid Swap maintains principles of freedom and anonymity. We support the original goals of cryptocurrency and provide a safe space for your transactions.",
  "p-about-registration-title": "Registration in Armenia",
  "p-about-registration-description":
    "Our platform is registered in a country with liberal cryptocurrency legislation, allowing us to operate within the law while maintaining our independence and principles.",
  "p-about-ease-title": "Ease of Use",
  "p-about-ease-description":
    "Our platform is optimized for use on all devices, ensuring a convenient and efficient cryptocurrency exchange.",
  "p-about-community-title": "Strong Community",
  "p-about-community-description":
    "Solid Swap is not just a platform, but a community where each member contributes to economic freedom and collective well-being. Together, we create an ecosystem that unlocks economic potential for each participant.",
  "p-about-subscribe-title": "Subscribe to Solid Swap",
  "p-about-contact-title": "Contact Us",
  "p-about-address": "Address:",
  "p-about-index": "Index:",
  "p-about-tax-id": "TAX ID:",
  "p-about-email": "Email:",
  "p-about-support-email": "Support Email:",
  "p-about-duns": "Data Universal Numbering System:",
  "p-about-duns-check": "Check D-U-N-S",
};
