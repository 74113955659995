export default {
  "p-user-index-welcome": "Welcome to your personal dashboard!",

  "p-user-notifications-settings": "Go to notification's settings",
  "p-user-notifications-all": "All notifications",
  "p-user-notifications-error": "Error while getting notifications",
  "p-user-notifications-none": "No notifications",

  "p-user-settings-head-title":
    "User Settings | Manage Your Solid Swap Account",
  "p-user-settings-head-description":
    "Manage your Solid Swap account settings and preferences.",
  "p-user-settings-title": "User settings",
  "p-user-settings-id": "ID",
  "p-user-settings-username": "Username",
  "p-user-settings-last-login": "Last login",

  "p-user-wallet-head-title": "User Wallet | Manage Your Solid Swap Account",
  "p-user-wallet-title": "Wallet transactions",
  "p-user-wallet-incoming-internal": "Incoming internal",
  "p-user-wallet-outgoing-internal": "Outgoing internal",
  "p-user-wallet-incoming": "Incoming",
  "p-user-wallet-outgoing": "Outgoing",
  "p-user-wallet-address-copied": "Your public address has been copied!",
  "p-user-wallet-using-network":
    "We are using {network} network for {currentTab}",
  "p-user-wallet-balance": "Your balance is:",
  "p-user-wallet-frozen-tooltip":
    "The amount of funds that are currently frozen in the systemm in orders, transfers, etc.",
  "p-user-wallet-frozen": "frozen",
  "p-user-wallet-show-address": "Show your public {currentTab} address",
  "p-user-wallet-copy": "Copy",
  "p-user-wallet-min-deposit-note-1":
    "Note that we have minimum limit for deposit crypto currencies.",
  "p-user-wallet-min-deposit-note-2":
    "The limit is about 50 {symbol} equivalent in crypto.",
  "p-user-wallet-current-rate": "With current exchange rate:",
  "p-user-wallet-usd-symbol": "50 {symbol}",
  "p-user-wallet-tfa-alert":
    "Please note: Withdrawals are not permitted unless Two-Factor Authentication (TFA) is enabled on your account.",
  "p-user-wallet-restriction-alert":
    "Your account is currently restricted from making withdrawals. Please contact support for further assistance",
  "p-user-wallet-withdraw": "Withdraw {currentTab}",
  "p-user-wallet-history-title": "Transactions history:",
  "p-user-wallet-no-transactions": "No transactions found for {currentTab}",

  "p-user-head-title": "User Page | Manage Your Solid Swap Account",
  "p-user-head-description":
    "Access and manage your Solid Swap account settings, wallet, and personal preferences securely",
  "p-user-head-keywords":
    "user settings, manage account, crypto wallet, Solid Swap user",
};
